.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.datatable{
  border: 1px solid rgb(209, 204, 204);
  
}
/* .isdzTY {
  background-color: rgb(243, 241, 240) !important;
} */
/* .buGObk{
  background-color: rgb(106, 170, 207) !important;
}  */
.heading{
  font-size: 30px;
}

/* .bPokRt{
  background-color: rgb(243, 241, 240) !important;
} 
.bPokRt:hover{
  background-color: rgb(185, 214, 231) !important;
}

.admin-banner-section{
  background-color: rgb(243, 241, 240);
} */



@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body{
  font-family: 'Poppins', sans-serif;
  height: 100;
  background-color: #1b1049;
  color: #fff;
}
.gradient-box {

  align-items: center;
  justify-content: center;
  width: 19rem;
  height: 19rem;
  margin: auto;
  position: relative;
  box-sizing: border-box;

  background: #FFF;
  background-clip: padding-box;
  /* !importanté */
  border: solid 5px transparent;
  /* !importanté */
  border-radius: 50%;
}
.gradient-box:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -23px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(to right, #3B33D5, #0b0b4d);
}
.total-value-earned-per .card{
  text-align: start;
}
.total-value-earned-icon{
  padding: 0px 6px;
  color: #FFF;
  font-size: 30px;
  border-radius: 4px;
}
.total-value-earned-per .card-title{
 font-size: 18px;
 font-weight: bold;
 letter-spacing: 1px;
}

.total-value-earned-per button{
  width: 100%;
}
.form-control{
  box-shadow: none !important;
}
.form-select{
  box-shadow: none !important;
}
.card{
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  border-left: 3px solid #3B33D5;
  padding: 20px;
}
.logout-button-dash{
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  background: linear-gradient(89.66deg, #6a68ef 7.4%, #fc58ff00 110.79%);
  border: 0;
  border-radius: 16px;
  font-size: 14px;
  padding: 10px 20px !important;
}
.dashboard-new-admin .navbar{
  border-bottom: 1px solid #adadad2f !important;
}
.btn-add-new-items{
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
  border: 0;
  border-radius: 16px;
  font-size: 14px;
  padding: 10px 20px !important;
}
.dashboard-new-admin .rdt_TableHeader{
  background: linear-gradient(180deg,#2d2f7626,#de705f26); 
  color: #FFF;
}
.dashboard-new-admin .rdt_TableHeadRow{
  background-color: #f8f9fa00 !important;
  border-bottom-color: rgba(255, 255, 255, 0.116) !important;
  color: #FFF;
  font-weight: 800;
}
.dashboard-new-admin .rdt_Table{
  /* background: linear-gradient(180deg,#2d2f7626,#de705f26) !important; */
  color: #FFF;
}
.dashboard-new-admin .rdt_TableBody{
  background-color: #f8f9fa00 !important;
  border-bottom-color: rgba(0, 0, 0, 0.041) !important;
  color: #FFF;
  font-weight: 800;
}
.dashboard-new-admin .rdt_TableRow{
  background-color: #f8f9fa00 !important;
  border-bottom-color: rgba(0, 0, 0, 0.041) !important;
  color: #FFF;
}
.dashboard-new-admin .rdt_TableCell{
  color: #FFF;
}
.dashboard-new-admin .rdt_Pagination{
  background-color: #f8f9fa00 !important;
  color: #FFF !important;
}
.dashboard-new-admin .rdt_Pagination button{
  fill: #FFF !important;
}
.edit-modal-design .modal-content{
  background: #2d153a;
}
.form-control{
  background-color: transparent;
  color: #FFF;
  border-color: #ffffff46;
}
.form-control:active, .form-control:focus{
  background-color: transparent;
  color: #FFF;
  border-color: #ffffff46;
}
.form-select{
  background-color: transparent;
  color: #FFF;
  border-color: #ffffff46;
}
.form-select:active, .form-select:focus{
  background-color: transparent;
  color: #FFF;
  border-color: #ffffff46;
}
option{
  color: #000;
}
.error{
  color: red;
}
.MuiBox-root{
  position: relative;
}
.items-new-content{
  
   position: relative;
  /* top: 38%; */
  /* right: 15%;  */
  margin-left: -35px;
  /* cursor: pointer; */
  
}
.img{
display: flex;
justify-content: center;
align-items: center;
margin-left: 170px;
}
.GO-CHILD-1{
  color:black;
  text-decoration: none;
  background-color:#f5f5f5;
  border-radius:10px;
}
.Go-child{
  display: flex;
  justify-content: center;
  margin-top:-20px;
}
.sidebar {
  background-color: rgba(0, 0, 5, 0.603) !important;
  padding-top: 100px !important;
}
.active {
  background: linear-gradient(89.66deg, #6a68ef 7.4%, #fc58ff00 110.79%);
  background-color: transparent !important;
}

#sidebar ul.nav li a:hover {
  background: linear-gradient(89.66deg, #6a68ef 7.4%, #fc58ff00 110.79%);
  background-color: transparent !important;
}
#sidebar ul.nav li {
  border-bottom: none !important;
}

.nnnn{
  background: red!important;
}

.table-dashboard{
  background: linear-gradient(180deg,#2d2f7626,#de705f26)!important;
}

.rdt_Table{
  background: #fc58ff00!important;
}